<template lang="pug">
.select(:class="{ show, selected: selected !== null && !!selected.length, invalid: required && (selected === null || !selected.length) }" :tabindex="tabindex" @blur="close")
	.select__placeholder {{ placeholder }}
	.select__value(@click="toggle")
		.select__text
			span {{ text }}
	.select__options
		.select__item(
			v-for="(value, key) of options"
			:key="key"
			:class="{active: multi && selected.includes(key)}"
			@click="!disabled ? setOption(key) : null"
		) {{ value }}
	.select__svg.select__svg--close(v-if="!multi" @click="clear")
		IconPlus(close :width="1")
	.select__svg.select__svg--chevron
		include ../../assets/img/chevron.svg
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
import IconPlus from './icons/IconPlus'

export default {
	name: 'BaseSelect',
	components: { IconPlus },
	props: {
		multi: {
			type: Boolean,
			default: false
		},
		options: {
			type: [Object, null],
			default: null
		},
		option: {
			type: [String, Number, Array, null],
			default: null
		},
		default: {
			type: [String, Array, null],
			default: null
		},
		placeholder: {
			type: [String, null],
			default: null
		},
		tabindex: {
			type: Number,
			default: 0
		},
		disabled: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			default: false
		}
	},
	emits: ['update:option'],
	setup (props, { emit }) {
		const selected = ref(props.default)
		const show = ref(false)

		onMounted(() => {
			if (selected.value) {
				if (props.multi) {
					selected.value = selected.value.map(item => String(item))

					emit('update:option', selected.value)
				} else {
					emit('update:option', Object.entries(props.options).find(([, value]) => value === selected.value)[0])
				}
			}
		})

		watch(
			() => props.option,
			(x) => {
				selected.value = props.options[x] || props.default
			}
		)

		const text = computed(() => {
			if (!props.multi) return selected.value
			if (selected.value.length) {
				if (typeof selected.value === 'string') {
					let key
					Object.entries(props.options).forEach(el => {
						if (el[1] === selected.value) key = el[0]
					})
					// eslint-disable-next-line
					selected.value = [key]
				}
				const arr = []
				selected.value.forEach(item => {
					arr.push(props.options[item])
				})
				return arr.join(', ')
			}
			return null
		})

		const toggle = () => {
			show.value = !show.value
		}

		const close = () => {
			show.value = false
		}

		const setOption = (key) => {
			if (props.multi) {
				const stringKey = String(key)

				const index = selected.value.indexOf(stringKey)

				index < 0
					? selected.value.push(stringKey)
					: selected.value.splice(index, 1)

				emit('update:option', selected.value)
			} else {
				selected.value = props.options[key]

				close()

				emit('update:option', key)
			}
		}

		const clear = () => {
			selected.value = null
			close()
			emit('update:option', null)
		}

		return {
			selected,
			show,
			toggle,
			close,
			text,
			setOption,
			clear
		}
	}
}
</script>
<style lang="scss" scoped>

.select {
	outline: none;
	position: relative;
	width: 100%;
	font-size: 16px;
	line-height: 40px;
	height: 42px;

	&__placeholder {
		pointer-events: none;
		position: absolute;
		top: 0;
		left: 16px;
		z-index: 2;
		color: $mainColor;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: -4px;
			right: -4px;
			bottom: 0;
			background-color: $bgPrimary;
			border-radius: $radiusPrimaryHalf;
			z-index: -1;
			display: none;
		}
	}

	&__value,
	&__item {
		height: 100%;
		cursor: pointer;
		user-select: none;
	}
	&__text {
		width: calc(100% - 60px);
		overflow: hidden;
		display: flex;
		padding: 0 5px 0 16px;
		span {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
	&__value {
		position: relative;
		cursor: pointer;
		user-select: none;
		background-color: $bgPrimary;
		border: 1px solid $borderPrimary;
		border-radius: $radiusPrimary;
		z-index: 1;
		width: 100%;
		display: grid;
	}

	&__options {
		pointer-events: none;
		background-color: $bgPrimary;
		border-radius: 0 0 $radiusPrimary $radiusPrimary;
		overflow: auto;
		max-height: 400px;
		border: 1px solid $borderPrimary;
		position: absolute;
		top: calc(100% - 6px);
		left: 0;
		right: 0;
		opacity: 0;
		transform: translateY(-34px);
		transition-property: opacity, transform;
		transition: 0.3s;
		&::-webkit-scrollbar {
			width: 8px;
		}
		&::-webkit-scrollbar-track {
			border-radius: 10px;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			background-color: #458eaf;
		}
	}

	&__item{
		position: relative;
		color: $mainColor;
		padding: 0 16px;
		&:first-child {
			padding-top: 6px;
		}

		&:hover{
			background-color: rgba($bgMain, 0.2);
		}

		&::after {
			content: '●';
			position: absolute;
			top: 50%;
			right: 16px;
			transform: translateY(-50%);
			opacity: 0;
			transition: opacity 0.3s;
		}

		&.active {
			&::after {
				opacity: 1;
			}
		}
	}

	&__svg {
		position: absolute;
		top: 11px;
		z-index: 2;
		pointer-events: none;
		transition-duration: 0.3s;

		svg {
			display: block;
			width: 18px;
			height: 18px;
		}

		&--close {
			right: 34px;
			cursor: pointer;
			opacity: 0;
			transition-property: opacity;
		}

		&--chevron {
			right: 6px;
			transition-property: transform;
		}
	}

	&.show {
		z-index: 5;

		.select {
			&__options {
				pointer-events: inherit;
				opacity: 1;
				transform: translateY(0);
			}

			&__svg {
				&--chevron {
					transform: rotate(-90deg);
				}
			}
		}
	}

	&.selected {
		.select {
			&__placeholder {
				transform-origin: top left;
				transform: scale(0.7) translateY(-9px);
				line-height: 20px;

				&::before {
					display: block;
				}
			}

			&__svg {
				&--close {
					opacity: 1;
					pointer-events: inherit;
				}
			}
		}
	}
	&.invalid {
		.select__value {
			border: 1px solid red;
		}
	}

	@include until-mobile {
		&__placeholder {
			font-size: 14px;
			left: 12px;
		}

		&__value,
		&__item {
			font-size: 14px;
			padding-left: 12px;
		}
	}

	@include for-tablet {
		&__value {
			transition-property: border-color, box-shadow;
			transition-duration: 0.3s;

			&:hover {
				border-color: $mainColorHover;
				box-shadow: $shadowPrimary;
			}
		}

		&__item{
			transition: background-color 0.3s;
		}

		&__svg {
			top: 8px;

			svg {
				width: 24px;
				height: 24px;
			}

			&--close {
				right: 40px;
			}

			&--chevron {
				right: 12px;
			}
		}

	}
}
</style>
