<template lang="pug">
.tooltip-wrapper
    .tooltip-content(v-if="showTooltip") {{ content }}
    .tooltip-trigger
        slot
</template>

<script>
import { ref } from 'vue'

export default {
	name: 'Tooltip',
	setup (props, { slots }) {
		const showTooltip = ref(true)

		return {
			showTooltip
		}
	},
	props: {
		content: {
			type: String,
			required: true
		}
	}
}
</script>

<style lang="scss" scoped>
.tooltip-wrapper {
  position: relative;
  display: inline-block;
  z-index: 3;
  .tooltip-content {
    min-width: fit-content;
    width: 20vw;
    position: absolute;
    bottom: 0%;
    left: 0%;
    transform: translateX(-100%);
    background-color: $error;
    color: #fff;
    padding: 8px;
    font-size: 14px;
    border-radius: 4px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0s linear 0.3s;
  }

  &:hover > .tooltip-content {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s, visibility 0s;
  }
}
</style>
